import {useForm} from "react-hook-form";
import {PushSettingsItemDto, PushSettingsUpdateDto} from "../types/notification";
import {useEffect, useState} from "react";
import {getNotiSettings, postPushSettings} from "../apis/notification";
import {nToast} from "../plugins/reactToast";
import moment from "moment/moment";

const useNotification = () => {
    const form = useForm<PushSettingsUpdateDto>();
    const [notiCategories, setNotiCategories] = useState<PushSettingsItemDto[]>([]);

    const onChangePushOn = () => {
        form.setValue('categories', notiCategories.map((category) => ({
            categoryId: category.category.id,
            pushOn: !form.getValues('pushOn')
        })));
        form.setValue('pushOn', !form.getValues('pushOn'));
        postPushSettings(form.getValues()).then(() => {
            form.getValues('pushOn') === true ? nToast('[칼하트윕] PUSH 수신 동의 처리') : nToast('[칼하트윕] PUSH 수신 거부 처리');
        });
    }

    const isChecked = (id: number) => {
        if (!form.getValues('categories')) return false;
        const category = form.watch('categories').find((category) => category.categoryId === id);
        return category ? category.pushOn : false;
    }

    const onChangePushDetails = (id: number, categoryName: string) => {
        if (form.getValues('pushOn') === false) {
            nToast('PUSH 수신을 먼저 동의해주세요.');
            return;
        }
        const category = form.getValues('categories').find((category) => category.categoryId === id)!;
        form.setValue('categories', form.getValues('categories').map((category) => {
            if (category.categoryId === id) {
                return {...category, pushOn: !category.pushOn};
            }
            return category;
        }));
        category.pushOn = !category.pushOn;

        postPushSettings(form.getValues()).then((res) => {
            category!.pushOn ?
                nToast(`[칼하트윕] ${categoryName} 수신 동의 처리 - ${moment().format('YYYY.MM.DD')}`) :
                nToast(`[칼하트윕] ${categoryName} 수신 거부 처리 - ${moment().format('YYYY.MM.DD')}`);
        });
    }

    const marketingPushOn = (isOn?: boolean) => {
        let pushOn = isOn ?? true;
        form.setValue('categories', notiCategories.map((category) => ({
            categoryId: category.category.id,
            pushOn: pushOn,
        })));
        form.setValue('pushOn', pushOn);
        postPushSettings(form.getValues()).then(() => {
            pushOn ? nToast('[칼하트윕] 마케팅 수신 동의 처리') : nToast('[칼하트윕] 마케팅 수신 거부 처리');
        });
    }

    useEffect(() => {
        getNotiSettings().then((res) => {
            const data = res.data.payload;

            const categories = data.categories.map((category) => ({
                categoryId: category.category.id,
                pushOn: category.pushOn
            }));

            form.setValue('pushOn', data.pushOn);
            form.setValue('categories', categories);
            form.setValue('store', 'CARHARTT');

            setNotiCategories(data.categories);
        });
    }, []);

    return {
        form,
        onChangePushOn,
        marketingPushOn,
        isChecked,
        onChangePushDetails
    }
}

export default useNotification;
