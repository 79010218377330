import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon } from './Icon';
import { ReactComponent as Tshirt } from '../assets/svg/Tshirt.svg';
import { ReactComponent as CarharttIcon } from '../assets/svg/icon-carhartt.svg';
import { ReactComponent as JournalIcon } from '../assets/svg/icon-journal.svg';
import { ReactComponent as ProfileIcon } from '../assets/svg/icon-profile.svg';
import { some } from 'lodash';
import {useUserDispatch, useUserState} from '../context/UserContext';
import { Bridge } from '../plugins/bridge';
import { BRAZE_EVENT } from '../constants/braze';
import { ContentTabEventProperties } from '../types/amplitudeEventProperties';

const DISABLE_BOTTOM_NAV_BAR_ROUTES = [
  '/product',
  '/journal/',
  '/lookbook/',
  '/cart/payment',
  '/inspection',
];

export const BottomNavBar = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const {isLoggedIn} = useUserState();
  const dispatch = useUserDispatch();

  if (
    some(DISABLE_BOTTOM_NAV_BAR_ROUTES, route => pathname.startsWith(route))
  ) {
    return null;
  }

  function onClickContent(content: string) {
    const contentEventProperties: ContentTabEventProperties = {
      content_tab: content
    }
    Bridge.sendAmplitude('content_menu_clicked', contentEventProperties);
    Bridge.sendBraze(BRAZE_EVENT.CONTENT_MENU_CLICKED, contentEventProperties);

  }

  return (
    <>
      <nav className="bg-brand-1 border-t -bottom-0.5 flex fixed w-full md:hidden z-40 border-none max_height_mq py-1.5">
        <button
          onClick={() => pathname == '/' ? window.scrollTo(0,0) : push('/')}
          className="flex flex-1 items-center flex-col justify-center outline-none space-y-1 focus:outline-none focus:ring-0 pt-2 pb-1">
          <CarharttIcon className="" />
          <div className="text-10 text-gray-400">홈</div>
        </button>
        {/*<button*/}
        {/*  onClick={() => {*/}
        {/*    window.open(*/}
        {/*      'https://www.carhartt-wip.com/musicplayer',*/}
        {/*      '_blank',*/}
        {/*    );*/}
        {/*  }}*/}
        {/*  className="flex flex-1 items-center flex-col justify-center outline-none space-y-1 focus:outline-none focus:ring-0 pt-2 pb-1">*/}
        {/*  <MusicIcon className="" />*/}
        {/*  <div className="text-10 text-gray-400">라디오</div>*/}
        {/*</button>*/}
        <button
            onClick={() => {onClickContent("신상품"); push('/p/남성/15')}}
            className="flex flex-1 items-center flex-col justify-center outline-none focus:outline-none focus:ring-0 pt-2 pb-1">
            <Tshirt className="wh-5" /><div className="text-10 text-gray-400">신상품</div>
        </button>
        <button
          onClick={() => {
            dispatch({type: 'SET_SALE', payload: 'false'})
            dispatch({type: 'SET_MID_SALE', payload: 'false'})
            push('/m/category');
          }}
          className="flex flex-1 items-center flex-col justify-center outline-none space-y-1 focus:outline-none focus:ring-0 pt-2 pb-1">
          <Icon.Menu className="text-white stroke-current wh-4" />
          <div className="text-10 text-gray-400">카테고리</div>
        </button>

        <button
          onClick={() => {onClickContent("기사"); push('/journal')}}
          className="flex flex-1 items-center flex-col justify-center outline-none space-y-1 focus:outline-none focus:ring-0 pt-2 pb-1">
          <JournalIcon className="" />
          <div className="text-10 text-gray-400">기사</div>
        </button>
        <button
          onClick={() => (isLoggedIn ? push('/mypage') : push('/login'))}
          className="flex flex-1 items-center flex-col justify-center outline-none space-y-1 focus:outline-none focus:ring-0 pt-2 pb-1">
          <ProfileIcon className="" />
          <div className="text-10 text-gray-400">마이</div>
        </button>
      </nav>
    </>
  );
};
