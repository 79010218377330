import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import { ReactComponent as LogoHan } from '../assets/svg/logoHan.svg';
import { ReactComponent as LogoBlack } from '../assets/svg/logo-black.svg';
import { ReactComponent as SearchIcon } from '../assets/svg/icon-search.svg';
import { ReactComponent as MusicIcon } from '../assets/svg/icon-music.svg';
import { ReactComponent as Profile } from '../assets/svg/icon-profile.svg';
import { ReactComponent as CartIcon } from '../assets/svg/icon-cart.svg';
import { ReactComponent as SaveIcon } from '../assets/svg/icon-save.svg';
import { ReactComponent as BackIcon } from '../assets/svg/backbutton-icon.svg';
import { ReactComponent as KrFlag } from '../assets/svg/krFlag.svg';
import { ReactComponent as BellIcon } from '../assets/icons/icon-bell-fill.svg';
import { ReactComponent as SgFlag } from '../assets/svg/sgFlag.svg';
import { ReactComponent as EuFlag } from '../assets/svg/euFlag.svg';
import { ReactComponent as MyFlag } from '../assets/svg/myFlag.svg';
import { ReactComponent as UsFlag } from '../assets/svg/usFlag.svg';
import { Icon } from './Icon';
import { Section } from './Section';
import { CartCounterBadge, sumTotalCountOfCarts } from './CartCounterBadge';
import { SearchField } from './SearchField';
import { getCategories, getSubCategories } from '../apis/category';
import { CartResponse, TCategory, TSubCategory } from '../types/types';
import { some } from 'lodash';
import { useUserDispatch, useUserState } from '../context/UserContext';
import { getCarts } from '../apis/products';
import ReactPixel from '../plugins/facebookPixel';
import gtag from '../plugins/gtag';
import NaverPixel from '../plugins/naverPixel';
import KakaoPixel from '../plugins/kakao';
import { Action, Bridge } from '../plugins/bridge';
import { ContentTabEventProperties } from '../types/amplitudeEventProperties';
import { BRAZE_EVENT } from '../constants/braze';
import moment from 'moment';
import { CountDown } from './CountDown';
import { HeaderBanner } from '../components/HeaderBanner';
import { OpenPopup } from '../components/popupToast';
import { Slide, ToastContainer } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { getNotiBadge } from '../apis/notification';
import qs from 'qs';
import debug from '../utils/logging.js';


const DISABLE_HEADER_ROUTES = ['/inspection'];

const MOBILE_PREV_NAV_ITEM_PATHNAMES = [
  '/product',
  '/journal',
  '/store',
  '/certification',
  '/work-with-us',
  '/daegudetail',
  '/cheonandetail',
  '/coexdetail',
  '/dongseongrodetail',
  '/gwangjudetail',
  '/history',
  '/hongdaedetail',
  '/corporate-responsibility',
  '/jejudetail',
  '/jeonjudetail',
  '/timedetail',
  '/apgujeongdetail',
  '/centumdetail',
  '/lottedetail',
  '/lookbook/2024fw-men',
  '/lookbook/2024fw-women',
  '/journal',
  '/one-brand',
  '/store/carhartt',
  '/cc/contact-us',
  '/cc/custom/3',
  '/cc/custom/4',
  '/cc/cautions-for-washing-and-handling',
  '/cc/faq',
  '/cc/custom/14',
  '/cc/custom/2',
  '/cc/custom/1',
  '/cc/custom/5',
  '/notification',
];

const SCROLL_PATH = ['/p/남성', '/p/여성', '/results', '/journal'];

function onClickWishlist() {
  Bridge.sendAmplitude('wishlist_clicked');
}

function onClickCart() {
  Bridge.sendAmplitude('cart_clicked');
}

export const TopNavBar = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [searchToggle, setSearchToggle] = useState(false);
  const [mobileSearchToggle, setMobileSearchToggle] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showItn, setShowItn] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [categories, setCategories] = useState<TCategory[]>([]);
  const [hovered, setHovered] = useState<TCategory>();
  const [subCategories, setSubCategories] = useState<TSubCategory[]>([]);
  const { isLoggedIn } = useUserState();
  const [myCart, setMyCart] = useState<CartResponse>({
    carts: [],
    deliveryPrice: 0,
  });
  const dispatch = useUserDispatch();
  const [ScrollY, setScrollY] = useState<number>(0);
  const [BtnStatus, setBtnStatus] = useState<boolean>(false); // 버튼 상태
  const [cookies] = useCookies();
  const [isApp, setIsApp] = useState<boolean>(true);
  const [hasNewNoti, setHasNewNoti] = useState<boolean>(false);


  const { setSale } = useUserState();
  const query = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const sale = query.sale as string || setSale;

  // 세일 탭 클릭
  const onClickSaleTab = () => {
    setShowMenu(false);
    sale === 'true' ? dispatch({ type: 'SET_SALE', payload: 'false' }) : dispatch({
      type: 'SET_SALE',
      payload: 'true',
    });
    history.push(`/p/sale/${hovered?.categoryName}/${hovered?.categoryId}?${qs.stringify({
      ...query,
      sale: 'true',
    })}`);
  };

  useEffect(() => {
    /*getAppClient().then(r => {
        setIsApp(Boolean(r.data));
    })*/
    if (
      !!!cookies['request-client'] &&
      navigator.userAgent.indexOf('!!!IN APP EVENT!!!') === -1
    ) {
      setIsApp(false);
    } else {
      setIsApp(true);
    }

    getNotiBadge().then(r => {
      const newCount = r.data.payload.new;
      if (newCount > 0) {
        setHasNewNoti(true)
      } else {
        setHasNewNoti(false)
      };
    });
  }, [pathname, isLoggedIn]);

  const showLogoBar = (resultAt: string) => {
    const now = moment();
    const minutesAfterResult = now.diff(moment(resultAt), 'minute');
    const EXPOSE_TIME_LIMIT = 2880;
    // console.log(minutesAfterResult)
    if (minutesAfterResult < EXPOSE_TIME_LIMIT && minutesAfterResult > 0) {
      return <LogoHan className="cursor-pointer" />;
    } else {
      return <Logo className="cursor-pointer" />;
    }
  };
  const itnClick = () => {
    setShowItn(!showItn);
  };
  const handleFollow = () => {
    setScrollY(window.scrollY);
    if (ScrollY > 500) {
      // 100 이상이면 버튼이 보이게
      setBtnStatus(true);
    } else {
      // 100 이하면 버튼이 사라지게
      setBtnStatus(false);
    }
  };
  const redirectToLink = (url: string) => {
    window.open(url, '_blank');
  };

  const handleTop = () => {
    // 클릭하면 스크롤이 위로 올라가는 함수
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setScrollY(0); // ScrollY 의 값을 초기화
    setBtnStatus(false); // BtnStatus의 값을 false로 바꿈 => 버튼 숨김
  };

  useEffect(() => {
    if (some(SCROLL_PATH, path => pathname.startsWith(path))) {
      const watch = () => {
        window.addEventListener('scroll', handleFollow);
      };
      watch();
      return () => {
        window.removeEventListener('scroll', handleFollow);
      };
    }
  });

  const ScrollPath = useMemo(
    () => some(SCROLL_PATH, item => pathname.startsWith(item)),
    [pathname],
  );

  const isMobilePrevNavItem = useMemo(
    () =>
      some(MOBILE_PREV_NAV_ITEM_PATHNAMES, item => pathname.startsWith(item)),
    [pathname],
  );

  function onClickMyPage() {
    Bridge.sendAmplitude('mypage_clicked');
  }

  function onClickContent(content: string) {
    const contentEventProperties: ContentTabEventProperties = {
      content_tab: content,
    };
    Bridge.sendAmplitude('content_menu_clicked', contentEventProperties);
    Bridge.sendBraze(BRAZE_EVENT.CONTENT_MENU_CLICKED, contentEventProperties);
  }

  useEffect(() => {
    if (pathname !== '/')
      Bridge.sendAmplitude('page_viewed', {
        path: pathname + search,
        path_name: pathname,
      });
  }, [pathname, search]);

  useEffect(() => {
    gtag.ads.conversionActions.enter();
    gtag.ads.conversionActions.pageView();

    ReactPixel.loaded();
    if (process.env.REACT_APP_FACEBOOK_PIXEL_ID) {
      ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
    }
  }, []);

  // TopNavBar 컴포넌트의 마운팅은 라우터 바깥에서 이뤄지고 있으므로,
  // useEffect 의존성이 비어있으면 현재의 리소스 상태를 올바르게 반영하기 어렵습니다.
  // 라우팅 변경을 감지할 수 있는 상태값을 의존성에 추가하여 재실행 스콥을 구성합니다.
  useEffect(() => {
    // 장바구니 데이터는 페이지 이동시 계속 추적 되어야 합니다.
    getCarts()
      .then(r => {
        setMyCart(r.data.payload);
        dispatch({
          type: 'SET_CART_COUNT',
          payload: sumTotalCountOfCarts(r.data.payload.carts),
        });
      })
      .catch(e => debug.log(e));

    ReactPixel.pageView();
    KakaoPixel.PageView();

    // const loggerRegUrl = /\/signup\/success|\/cart\/payment\/success|\/product\/[0-9]+/
    // if (!loggerRegUrl.test(pathname))
    //   Logger.pageView();

    const naverRegUrl = /\/signup\/success|\/cart\/payment\/success/;
    if (!naverRegUrl.test(pathname))
      NaverPixel.pageView();
  }, [pathname, dispatch]);

  useEffect(() => {
    getCategories()
      .then(r => {
        const data = r.data.payload;
        data.sort((a, b) => b.sort - a.sort);
        setCategories(data);
      })
      .catch(e => debug.log(e));
  }, []);

  const onMouseOverOnCategory = (categoryId: number) => {
    getSubCategories(categoryId)
      .then(r => {
        const data = r.data.payload;
        data.sort((a, b) => b.sort - a.sort);
        setSubCategories(data);
      })
      .catch(e => debug.log(e));
  };

  const onSearchProduct = () => {
    history.push(`/results/${encodeURIComponent(searchText)}`, {
      keyword: searchText,
    });
    ReactPixel.search(searchText);
    setSearchText('');
    setSearchToggle(false);
    setMobileSearchToggle(false);
  };

  if (some(DISABLE_HEADER_ROUTES, route => pathname.startsWith(route))) {
    return null;
  }
  const DesktopNavBar = (
    <>
      <OpenPopup />
      <div className={`sticky top-0`}>
        <div className="bg-brand-1 text-white pl-4 flex items-center justify-between h-14">
          <div className="w-1/6">
            <div
              onClick={() =>
                pathname === '/' ? window.scrollTo(0, 0) : history.push('/')
              }
              className="inline-block">
              {showLogoBar('2022-10-09 00:00:00')}
            </div>
          </div>

          <div className="xl:space-x-12 space-x-5 flex flex-1">
            {categories.map((item, index) => (
              <Link
                key={index}
                onMouseOver={() => {
                  dispatch({ type: 'SET_SALE', payload: 'false' });
                  dispatch({ type: 'SET_MID_SALE', payload: 'false' });
                  onMouseOverOnCategory(item.categoryId);
                  setShowMenu(true);
                  setHovered(item);
                }}
                className={`
              h-14 grid place-content-center tn_hover 
              ${item?.categoryName === '시즌오프' && 'PC_BF_NAV'}
              ${
                  item?.categoryName === '미드세일' &&
                  'text-color-fe7b1e font-semibold'
                }
              `}
                // className="h-14 grid place-content-center tn_hover"
                to={`/p/${hovered?.categoryName}/${hovered?.categoryId}${
                  hovered?.categoryName === '악세사리'
                    ? '?categoryCodes=AC,OT,BA,HE,UNSO'
                    : hovered?.categoryName === '시즌오프'
                      ? '?sale=true'
                      : ''
                }`}>
                {item.categoryName}
              </Link>
            ))}
            <Link
              onClick={() => onClickContent('룩북')}
              onMouseOver={() => setShowMenu(false)}
              className="h-14 grid place-content-center tn_hover"
              to="/lookbook">
              룩북
            </Link>
            <Link
              onClick={() => onClickContent('기사')}
              className="h-14 grid place-content-center tn_hover"
              to="/journal">
              기사
            </Link>
            <Link
              onClick={() => onClickContent('매장')}
              className="h-14 grid place-content-center tn_hover"
              to="/store">
              매장
            </Link>
            <Link
              onClick={() => onClickContent('브랜드')}
              className="h-14 grid place-content-center tn_hover"
              to="/one-brand">
              브랜드
            </Link>
          </div>
          {!isApp && (
            <>
              <button
                onClick={itnClick}
                className={`wh-14 grid place-content-center tn_hoveri ${
                  showItn && 'bg-gray-900'
                }`}>
                <div
                  className={`flex items-center justify-center rounded-full overflow-hidden wh-5 relative bg-white`}>
                  <KrFlag />
                </div>
              </button>
              {showItn && (
                <div
                  style={{ maxWidth: '336px' }}
                  className={`absolute right-0 top-14 bg-white z-50 text-gray-800 w-full flex flex-col border`}>
                  <h3
                    className={`py-3 text-center text-12 border-b font-medium`}>
                    칼하트윕 글로벌
                  </h3>
                  <ul className={`overflow-y-scroll no-scrollbar`}>
                    <li
                      onClick={() =>
                        redirectToLink('https://www.carhartt-wip.com/')
                      }
                      className={`py-3 pl-3 flex items-center gap-x-4 border-b cursor-pointer hover:bg-gray-50`}>
                      <div
                        className={`flex items-center justify-center rounded-full overflow-hidden wh-5 relative bg-white`}>
                        <EuFlag />
                      </div>
                      <span>유럽</span>
                    </li>
                    <li
                      onClick={() =>
                        redirectToLink('https://us.carhartt-wip.com/')
                      }
                      className={`py-3 pl-3 flex items-center gap-x-4 border-b cursor-pointer hover:bg-gray-50`}>
                      <div
                        className={`flex items-center justify-center rounded-full overflow-hidden wh-5 relative bg-white`}>
                        <UsFlag />
                      </div>
                      <span>미국</span>
                    </li>
                    <li
                      onClick={() => redirectToLink('https://carhartt-wip.jp/')}
                      className={`py-3 pl-3 flex items-center gap-x-4 border-b cursor-pointer hover:bg-gray-50`}>
                      <div
                        className={`flex items-center justify-center rounded-full overflow-hidden wh-5 relative bg-gray-100`}>
                        <span
                          style={{ backgroundColor: '#bc002d' }}
                          className={`wh-2 rounded-full`}
                        />
                      </div>
                      <span>일본</span>
                    </li>
                    <li
                      onClick={() =>
                        redirectToLink('https://www.carhartt-wip.com.my/')
                      }
                      className={`py-3 pl-3 flex items-center gap-x-4 border-b cursor-pointer hover:bg-gray-50`}>
                      <div
                        className={`flex items-center justify-center rounded-full overflow-hidden wh-5 relative bg-white`}>
                        <MyFlag />
                      </div>
                      <span>말레이시아</span>
                    </li>
                    <li
                      onClick={() =>
                        redirectToLink('https://carhartt-wip.com.sg/')
                      }
                      className={`py-3 pl-3 flex items-center gap-x-4 border-b cursor-pointer hover:bg-gray-50`}>
                      <div
                        className={`flex items-center justify-center rounded-full overflow-hidden wh-5 relative bg-white`}>
                        <SgFlag />
                      </div>
                      <span>싱가포르</span>
                    </li>
                  </ul>
                </div>
              )}
            </>
          )}
          <div className={`flex`}>
            <button
              onClick={() => setSearchToggle(true)}
              className="wh-14 grid place-content-center tn_hoveri">
              <SearchIcon />
            </button>

            <button
              onClick={() => {
                onClickRadio();
                window.open(
                  'https://www.carhartt-wip.com/musicplayer',
                  '_blank',
                );
              }}
              className="wh-14 grid place-content-center tn_hoveri">
              <MusicIcon />
            </button>
            <button
              onClick={() => {
                Bridge.postMessage(Action.badgeCount, 0);
                history.push('/notification');
              }}
              className="wh-14 grid place-content-center tn_hoveri">
              <BellIcon />
              {hasNewNoti && (
                <span
                  className="w-2 h-2 bg-[#FF2323] absolute border border-[#363636] rounded-2xl mt-[18px] ml-[30px]" />
              )}
            </button>
            <Link
              to={isLoggedIn ? '/mypage' : '/login'}
              onClick={() => onClickMyPage()}
              className="wh-14 grid place-content-center tn_hoveri">
              <Profile />
            </Link>

            <Link
              to={isLoggedIn ? '/my/liked' : '/login'}
              className="wh-14 grid place-content-center tn_hoveri"
              onClick={() => {
                onClickWishlist();
              }}>
              <SaveIcon />
            </Link>

            <Link
              to="/cart"
              className="wh-14 grid place-content-center relative tn_hoveri"
              onClick={() => {
                onClickCart();
              }}>
              <CartIcon />
              <CartCounterBadge carts={myCart.carts} />
            </Link>
          </div>
        </div>
      </div>
    </>
  );

  const DesktopSearchBar = (
    <>
      <div className="bg-white px-4 flex items-center justify-between h-14">
        <div className="w-1/6">
          <Link to="/" className="">
            <LogoBlack className="" />
          </Link>
        </div>

        <div className="space-x-4 flex flex-1">
          <SearchField
            placeholder="검색하기"
            autoFocus={true}
            onChange={e => setSearchText(e.target.value)}
            onSearch={onSearchProduct}
          />
        </div>

        <button
          onClick={() => setSearchToggle(false)}
          className="bg-gray-100 rounded-full flex space-x-2 p-2">
          <span className="">닫기</span>
          <Icon.XRound />
        </button>
      </div>
      <div
        onClick={() => setSearchToggle(false)}
        className="z-30 w-full absolute h-screen bg-littleblack"
      />
    </>
  );

  const MobileNavBar = (
    <>
      <div className="sticky -top-0.5 z-40 md:hidden grid grid-cols-3 items-center bg-brand-1 h-11">
        <div className="flex justify-start">
          {isMobilePrevNavItem && (
            <button
              className="grid place-content-center wh-11"
              onClick={() => history.goBack()}>
              <BackIcon />
            </button>
          )}
          <Link
            to={isLoggedIn ? '/my/liked' : '/login'}
            onClick={() => {
              onClickWishlist();
            }}
            className="grid place-content-center wh-11">
            <SaveIcon />
          </Link>
          {window.location.pathname === '/' && (
            <Link
              onClick={() => {
                Bridge.postMessage(Action.badgeCount, 0);
              }}
              to={'/notification'}
              className="grid place-content-center wh-11">
              <BellIcon />
              {hasNewNoti && (
                <span
                  className="w-2 h-2 bg-[#FF2323] absolute border border-[#363636] rounded-2xl mt-[12px] ml-[24px]" />
              )}
            </Link>
          )}
        </div>
        <div className="flex justify-center">
          <div
            onClick={() =>
              pathname === '/' ? window.scrollTo(0, 0) : history.push('/')
            }
            className="">
            {showLogoBar('2022-10-09 00:00:00')}
          </div>
        </div>

        <div className="flex justify-end">
          <button
            onClick={() => setMobileSearchToggle(true)}
            className="wh-11 grid place-content-center">
            <SearchIcon />
          </button>
          <Link
            to="/cart"
            className="wh-11 grid place-content-center relative"
            onClick={() => {
              onClickCart();
            }}>
            <CartIcon />
            <CartCounterBadge carts={myCart.carts} />
          </Link>
        </div>
      </div>
    </>
  );

  const MobileSearchBar = (
    <>
      <div
        onClick={() => setMobileSearchToggle(false)}
        className={`fixed inset-0 z-50 bg-black bg-opacity-50`}
      />
      <div className="sticky top-0 z-50 flex flex-col">
        <div className="flex items-center gap-x-2 px-2 py-1.5 bg-white">
          <LogoBlack onClick={() => history.push('/')} />
          <SearchField
            placeholder="검색하기"
            className="w-full"
            autoFocus={true}
            onChange={e => setSearchText(e.target.value)}
            onSearch={onSearchProduct}
          />
          <Icon.XRound
            onClick={() => setMobileSearchToggle(false)}
            className="absolute right-4"
          />
        </div>
      </div>
    </>
  );

  return (
    <>
      {/* Desktop */}
      {ScrollPath && (
        <button
          className={BtnStatus ? 'topBtn active' : 'topBtn'} // 버튼 노출 여부
          onClick={handleTop} // 버튼 클릭시 함수 호출
        >
          <Icon.ChevronUp className="w-full" />
        </button>
      )}
      {isLoggedIn ? (
        <>
          <div className="md:block hidden">
            <ToastContainer transition={Slide} />
          </div>
          <div className="md:hidden block">
            <ToastContainer transition={Slide} position="top-center" />
          </div>
        </>
      ) : (
        <div className={`relative h-7`}>
          <div className="md:block hidden">
            <ToastContainer transition={Slide} />
          </div>
          <div className="md:hidden block">
            <ToastContainer transition={Slide} position="top-center" />
          </div>
          <HeaderBanner />
        </div>
      )}
      <div className="hidden md:block relative sticky top-0 text-xs z-40">
        <div className={`relative z-40`}>
          {searchToggle ? (
            <>
              <>{DesktopSearchBar}</>
            </>
          ) : (
            <>{DesktopNavBar}</>
          )}
        </div>

        {showMenu && !searchToggle && (
          <Section
            data-aos="fade-down"
            onMouseLeave={() => setShowMenu(false)}
            className={`hidden md:flex z-30 absolute bg-white w-full px-3 py-6 border-b border-gray-200`}>
            <div
              data-aos="fade-in"
              data-aos-duration="1000"
              className="w-1/6 ml-3.5 text-12">
              {hovered?.categoryName}
            </div>

            <div data-aos="fade-in" data-aos-duration="1000" className="flex flex-col flex-wrap h-56 gap-y-3 gap-x-3">
              <Link
                to={`/p/${hovered?.categoryName}/${hovered?.categoryId}${
                  hovered?.categoryName === '악세사리'
                    ? '?categoryCodes=AC,OT,SE,BA,HE,UNSO'
                    : hovered?.categoryName === '시즌오프'
                      ? '?sale=true'
                      : ''
                }`}
                className={`h-4 w-24 text-12`}
                onClick={() => setShowMenu(false)}>
                <span className="hover:bg-gray-200 rounded-sm px-2 py-1">
                  {hovered?.categoryName === '악세사리' ||
                  hovered?.categoryName === '시즌오프'
                    ? '전체'
                    : '신상품'}
                </span>
              </Link>

              {subCategories.sort((a, b) => b.sort - a.sort).map((item, index) => (
                <Link
                  key={index}
                  to={`/p/${hovered?.categoryName}/${hovered?.categoryId}/${item.subcategoryId}${hovered?.categoryName === '시즌오프' ? '?sale=true' : ''}`}
                  className={`h-4 w-24 text-12`}
                  onClick={() => setShowMenu(false)}
                >
                    <span className="hover:bg-gray-200 rounded-sm px-2 py-1">
                      {item.name}
                    </span>
                </Link>
              ))}
              {(hovered?.categoryName === '남성' || hovered?.categoryName === '여성' || hovered?.categoryId === 32) && (
                <>
                  <button className={`text-left mt-7`}
                          onClick={() => onClickSaleTab()}
                  >
                      <span className="hover:bg-gray-200 text-red-500 rounded-sm px-2 py-1">
                        세일
                      </span>
                  </button>
                </>
              )}
            </div>
            {
              // hovered?.categoryName !== "미드세일" &&
              hovered?.categoryName === '시즌오프' && <CountDown />
            }
          </Section>
        )}
      </div>

      {/* Mobile */}
      {mobileSearchToggle ? <>{MobileSearchBar}</> : <>{MobileNavBar}</>}
    </>
  );
};

function onClickRadio() {
  Bridge.sendAmplitude('radio_clicekd');
}
