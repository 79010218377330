import axios from 'axios';
import qs from 'qs';
import { nToast } from './reactToast';
import * as Sentry from '@sentry/react';
import { cacheAdapterEnhancer } from 'axios-extensions';
import { Action, Bridge } from './bridge';
import debug from '../utils/logging.js';
import moment from 'moment';

export const API_BASE_URL =
  window.location.origin?.includes('entropyparadox.link')
    ?`${process.env.REACT_APP_API_URL}` : `${process.env.REACT_APP_REAL_API_URL}`

const authToken = localStorage.getItem('authToken');
const now = moment();
const start = moment('2024-06-15 00:00:00');
const end = moment('2024-06-15 04:00:00');
const isWithinTimeRange = now.isBetween(start, end, undefined, '[]');

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: process.env.REACT_APP_MODE === 'development' ? {
    'Content-Type': 'application/json',
    'debug-site': 'carhartt',
  } : {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 9000,
  adapter: cacheAdapterEnhancer(axios.defaults.adapter!, {
    enabledByDefault: false,
  }),
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: 'comma' });
  },
});

api.interceptors.request.use(config => {
  const xClientId = localStorage.getItem('x-client-id');
  if (authToken) {
    config.headers.Authorization = `bearer ${authToken}`;
  } else {
    delete config.headers.Authorization;
  }
  if (xClientId) {
    config.headers['X-Client-Id'] = xClientId;
  } else {
    delete config.headers['X-Client-Id'];
  }
  return config;
});

api.interceptors.response.use(
  res => {
    if (isWithinTimeRange && window.location.pathname.indexOf('/inspection') === -1) {
      return window.location.replace('/inspection');
    }
    if (!!res.headers.authorization) {
      localStorage.setItem('authToken', res.headers.authorization);
      Bridge.postMessage(Action.setToken, res.headers.authorization);
    }
    return res;
  },
  error => {
    if (process.env.REACT_APP_MODE === 'development')
      debug.log(error.config.url, error.status);
    if (isWithinTimeRange && window.location.pathname.indexOf('/inspection') === -1) {
      return window.location.replace('/inspection');
    }
    if (error.response.status === 401) {
      nToast(error.response.data.message || error.response.data.error);
      if (window.location.pathname !== '/login')
        window.location.assign('/login');
    } else if (error.response.status === 403) {
    } else if (
      error.response.status === 307 &&
      window.location.pathname !== '/307'
    ) {
      window.location.assign('/307');
    }
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

export { api };
