import React, { useEffect, useState } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { ReactComponent as Insta } from '../assets/svg/Social_instagram.svg';
import { ReactComponent as Facebook } from '../assets/svg/Social_facebook.svg';
import { ReactComponent as Youtube } from '../assets/svg/Social_Youtube.svg';
import { ReactComponent as Pinterest } from '../assets/svg/Social_Pinterest.svg';
import { ReactComponent as SoundCloud } from '../assets/svg/Icons_Social.svg';
import { Bridge } from '../plugins/bridge';
import {some} from "lodash";
import moment from 'moment';

const DISABLE_FOOTER_ROUTES = [
  '/inspection',
];

const SOCIAL_INFO = [
  {
    Icon: Insta,
    size: 'wh-10',
    link: 'https://www.instagram.com/carharttwip_kr/?hl=ko&open_type=new',
  },
  {
    Icon: Facebook,
    size: 'wh-9',
    link: 'https://www.facebook.com/carharttwipkr/?open_type=new',
  },
  {
    Icon: Youtube,
    size: 'wh-9',
    link: 'https://www.youtube.com/user/carharttwipkr/featured?open_type=new',
  },
  {
    Icon: Pinterest,
    size: 'wh-9',
    link: 'https://www.pinterest.co.kr/carharttwip/?open_type=new',
  },
  {
    Icon: SoundCloud,
    size: 'wh-9',
    link: 'https://soundcloud.com/carharttwip?open_type=new',
  },
];

function onClickSocialLink(link : string) {
  switch(link) {
    case 'https://www.instagram.com/carharttwip_kr/?hl=ko&open_type=new' :
      Bridge.sendAmplitude('social_link_icon_clicked', {social_link_icon : 'instagram'});
      break;

    case 'https://www.facebook.com/carharttwipkr/?open_type=new' :
      Bridge.sendAmplitude('social_link_icon_clicked', {social_link_icon : 'facebook'});
      break;

    case 'https://www.youtube.com/user/carharttwipkr/featured?open_type=new' :
      Bridge.sendAmplitude('social_link_icon_clicked', {social_link_icon : 'youtube'});
      break;

    case 'https://www.pinterest.co.kr/carharttwip/?open_type=new' :
      Bridge.sendAmplitude('social_link_icon_clicked', {social_link_icon : 'pinterest'});
      break;

    case 'https://soundcloud.com/carharttwip?open_type=new' :
            Bridge.sendAmplitude('social_link_icon_clicked', {social_link_icon : 'soundcloud'});
      break;

    default : return;
  }
}


export const Footer = () => {
  const { pathname } = useLocation();

  const [currentYear, setCurrentYear] = useState<number | null>(null);

  useEffect(() => {
    const fetchCurrentYear = () => {
      const year = moment().year();
      setCurrentYear(year);
    };

    fetchCurrentYear();
  }, []);

  if (
      some(DISABLE_FOOTER_ROUTES, route => pathname.startsWith(route))
  ) {
    return null;
  }

  return (
    <div className="px-8 border-t border-gray-100 pt-10 mt-10 pb-6 bg-white" data-aos="fade-in" data-aos-delay="300">
      <div className="md:flex flex-none flex-col md:flex-row md:space-x-44 md:space-y-0 space-y-6 pb-10">
        <div className="flex flex-col space-y-3 text-12 float-left w-1/2 md:w-auto">
          <div className="font-semibold">고객 지원</div>
          <Link to="/cc/contact-us" className="">
            문의하기
          </Link>
          <Link to="/cc/custom/3" className="">
            배송 안내
          </Link>
          <Link to="/cc/custom/4" className="">
            반품 안내
          </Link>
          <Link to="/mypage/edit" className="">
            나의 계정
          </Link>
          <Link to="/cc/faq" className="">
            자주하는 질문
          </Link>
          <div className="flex flex-col md:hidden text-12 space-y-3">
            {/*<Link to="/terms" className="">*/}
            {/*  이용약관*/}
            {/*</Link>*/}
            <Link to="/personalpolicy" className="">
              개인정보취급 방침
            </Link>
          </div>
        </div>

        <div className="flex flex-col space-y-3 text-12 w-1/2 md:w-auto">
          <div className="font-semibold">기업정보</div>
          <Link to="/history" className="">
            칼하트윕에 대하여
          </Link>
          <a
            href="https://www.carhartt-wip.com/musicplayer"
            target="_blank"
            rel="noopener noreferrer">
            칼하트윕 라디오
          </a>
          <Link to="/corporate-responsibility" className="">
            기업의 사회적 책임
          </Link>
          <Link to="/work-with-us" className="">
            채용 안내
          </Link>
          <Link to="/terms" className="">
            이용약관
          </Link>
          {/*<Link to="/certification" className="">*/}
          {/*  일반적합인증*/}
          {/*</Link>*/}
        </div>

        <div className="text-12 text-gray-600 mt-12 leading-5 md:absolute relative right-8 md:text-right text-left md:ml-0 ml-8 md:block hidden">
          <p className="text-12 mb-3 text-gray-900 font-bold">주식회사 웍스아웃</p>
          <p>
            사업자등록번호: 106-86-85373 | 대표 : 강승혁, 박선영
            <br />
            전자우편주소: customer@carhartt-wip.co.kr | 대표전화: 02-541-0854
            <br />
            주소: 서울시 마포구 월드컵북로23길 24
            <br />
            사업자등록번호: 106-86-85373
            <br />
            통신판매업신고: 2014-서울마포-0219
            <br />
            호스팅 사업자: 아마존웹서비스
          </p>
        </div>
      </div>
      <div className="text-12 text-gray-600 mt-12 md:absolute relative right-8 md:text-right text-left md:ml-0 ml-8 md:hidden block">
        <p className="text-12 mb-3 text-gray-900 font-bold">주식회사 웍스아웃</p>
        <p>
          사업자등록번호: 106-86-85373 | 대표 : 강승혁, 박선영
          <br />
          전자우편주소: customer@carhartt-wip.co.kr | 대표전화: 02-541-0854
          <br />
          주소: 서울시 마포구 월드컵북로23길 24
          <br />
          사업자등록번호: 106-86-85373
          <br />
          통신판매업신고: 2014-서울마포-0219
          <br />
          호스팅 사업자: 아마존웹서비스
        </p>
      </div>


      <div className="text-12 text-gray-600 pt-8 flex flex-col-reverse space-y-reverse md:flex-row md:justify-between">
        <div className="flex lg:space-x-8 space-x-3 mt-6 md:ml-0 ml-2">
          <div className="">© 칼하트윕 코리아 {currentYear !== null && <>{currentYear}</>}</div>
          <Link to="/terms" className="hidden md:block">
            이용약관
          </Link>
          <Link to="/personalpolicy" className="hidden md:block">
            개인정보취급 방침
          </Link>
          <p className="pl-2 md:block hidden">
            <a
              href="https://admin.kcp.co.kr/Modules/escrow/kcp_pop.jsp?site_cd=IP28D"
              target="_blank"
              rel="noopener noreferrer"
            >
              구매안전 (에스크로) 서비스 가입사실 확인
            </a>
          </p>

        </div>
        <p className="pl-2 md:hidden block">
          <a
            href="https://admin.kcp.co.kr/Modules/escrow/kcp_pop.jsp?site_cd=IP28D"
            target="_blank"
            rel="noopener noreferrer"
          >
            구매안전 (에스크로) 서비스 가입사실 확인
          </a>
        </p>
        <div className="flex md:mspace-x-10 space-x-4 md:mt-4 -mt-2 md:pb-0 pb-5">
          {SOCIAL_INFO.map((item, index) => {
            const { link, Icon, size } = item;
            return (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                key={link}
                onClick={()=>{onClickSocialLink(link)}}
                >
                <Icon className="wh-7" />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};
