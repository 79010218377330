import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './plugins/bridge';
import App from './App';
import './index.css';
import './sik.css';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { HelmetProvider } from 'react-helmet-async';
import netfunnel from './plugins/netfunnel';
// Sentry.init({
//   dsn: 'https://7eb45dcfad6c4bfa9e1108eae6de9cec@sentry.worksout.co.kr/4',
//   integrations: [new Integrations.BrowserTracing({
//     tracingOrigins: ['::1'],
//   })],
//   environment: process.env.REACT_APP_MODE,
//   tracesSampleRate: 1.0,
// })
export const NetFunnel:any  = netfunnel;
const client = new QueryClient();
ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <HelmetProvider>
        <App />
        <ReactQueryDevtools initialIsOpen={false}/>
      </HelmetProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
