import {
  Agreements, AgreementType, ChangePasswordDto,
  CustomerBoard,
  CustomerBoardDetailDto,
  CustomerBoardDto,
  CustomerBoardList,
  FaqResponse, FindPasswordDto,
  LoginRequest, NiceRequestDto, NiceServiceDto,
  Paged, ServiceResponse,
  SignupRequest,
  TResponse,
  UserAddress,
  UserAddressCreateDto,
  UserFindEmailDto,
  UserFindPasswordDto,
  UserInfo,
  UserInfoModifyDto,
  UserMileageHistoryResponse, UserNiceDto,
  ViewUserCoupon,
} from '../types/types';
import { api } from '../plugins/axios';
import { Action, Bridge } from '../plugins/bridge';
import debug from '../utils/logging.js';

export const getAgreements = () => {
  return api.get<TResponse<Agreements>>('/v1/agreements');
};

export const signup = (data: SignupRequest) => {
  return api.post('/v1/users', data);
};

export const userLogin = (data: LoginRequest) => {
  return api.post('/v1/users/login', data);
};

export const userLogout = () => {
  localStorage.removeItem('authToken');
  Bridge.postMessage(Action.removeToken);
  api.interceptors.request.use(config => {
    delete config.headers.Authorization;
    return config;
  });
  // airbridge.clearUser();
  Bridge.sendAirbridge('clearUser');
  Bridge.sendAmplitude('sign_out_completed');
  Bridge.sendAmplitude('setUserId', null);
  return api.get('/v1/users/logout');
};

export const getUserInfo = () => {
  return api.get<TResponse<UserInfo>>('/v1/users/info');
};

export const putUserInfo = (data: UserInfoModifyDto) => {
  return api.put<ServiceResponse>(`/v1/users`, data);
};

export const putUserAgreement = (data: AgreementType[]) => {
  return api.put<ServiceResponse>('/v1/users/agreements', data);
};

export const changeAddress = (data: UserAddressCreateDto) => {
  return api.put('/v1/users/address', data);
};

export const getNiceRequest = (dto: NiceRequestDto) => {
    return api.post<ServiceResponse<NiceServiceDto>>(`/v1/nice/verifications`, dto);
};

export const getUserNice = (tokenVersionId:string) => {
  return api.get<TResponse<UserNiceDto>>(`/v1/nice/user?tokenVersionId=${tokenVersionId}`);
}

export const userVerifications = (phone: string) => {
  const data = { phone };
  return api.post(`/v1/verifications`, data);
};

export const userPhoneVerify = (data: { code: string; phone: string }) => {
  return api.post(`/v1/verifications/verify`, data);
};

export const getMileages = (page: number, size: number) => {
  const params = { page, size };
  return api.get<TResponse<Paged<UserMileageHistoryResponse>>>(`/v1/mileages`, {
    params,
  });
};

export const getMyCoupons = () => {
  return api.get<TResponse<ViewUserCoupon[]>>(`/v1/coupon/user`);
};

export const getCustomers = (page: number, size: number) => {
  const params = { page, size };
  return api
    .get<TResponse<Paged<CustomerBoardList>>>(`/v1/customers`, {
      params,
    })
    .catch(e => debug.log('getCustomers e : ', e));
};

export const postCustomers = (data: CustomerBoardDto, files: File[]) => {
  let formData = new FormData();
  for (let file of files) {
    formData.append(`files`, file);
  }
  formData.append(
    'request',
    new Blob([JSON.stringify(data)], { type: 'application/json' }),
  );
  return api.post<TResponse<CustomerBoard>>(`/v1/customers`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getCustomerBoardDetail = (customerBoardId: number) => {
  return api.get<TResponse<CustomerBoardDetailDto>>(
    `/v1/customers/${customerBoardId}`,
  );
};

export const postReCustomers = (customerBoardId: number, contents: string) => {
  const data = { contents };
  return api.post<TResponse<void>>(`/v1/customers/${customerBoardId}`, data);
};

export const deleteCustomers = (customerBoardId: number) => {
  return api.delete<TResponse<void>>(`/v1/customers/${customerBoardId}`);
};

export const getUserAddresses = () => {
  return api.get<TResponse<UserAddress[]>>(`/v1/users/addresses`);
};

export const removeAddress = (addressId:number) => {
  return api.put<TResponse<void>>(`/v1/users/address/${addressId}`)
};

export const getFaqs = () => {
  const params = { pageSize: 1000, pageNumber: 0 };
  return api.get<TResponse<Paged<FaqResponse>>>(`/v1/faqs`, { params });
};

export const checkEmailAndPhone = (
  targetType: 'EMAIL' | 'PHONE',
  target: string,
) => {
  const url = `/v1/users/duplicated`;
  const params = { targetType, target };
  return api.get<TResponse<boolean>>(url, { params });
};

export const findUserId = (data: UserFindEmailDto) => {
  const url = `/v1/users/find-id`;
  return api.post<TResponse<string[]>>(url, data);
};

export const findUserPassword = (data: FindPasswordDto) => {
  return api.post<ServiceResponse>('/v1/users/find-password', { ...data });
};

export const changeUserPassword = (data: ChangePasswordDto) => {
  return api.post<TResponse<void>>('/v1/users/change-password', data);
};

export const modifyPassword = (data: UserFindPasswordDto) => {
  const url = `/v1/users/modify-password`;
  return api.post<TResponse<void>>(url, data);
};

export const withDraw = () => {
  Bridge.sendBraze('withdraw');
  return api.delete<TResponse<void>>('/v1/users/withDraw');
};

export const getAppClient = () => {
  return api.get<TResponse<boolean>>('/v1/users/getAppClient');
};

export const checkNetFunnelKey = (netFunnelKey:string) => {
  return api.get(`/v1/users/netFunnelAction?netFunnelKey=${netFunnelKey}`);
};