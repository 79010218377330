import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from 'swiper/core';
import {getContents} from '../apis/contents';
import { Bridge } from '../plugins/bridge';
import moment from "moment/moment";
import {useUserState} from "../context/UserContext";

SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export const HeaderBanner = () => {
  const { push} = useHistory()
  const {isLoggedIn} = useUserState();
  return (
      <>
        <style>
          {`
              .swiper-container {
              direction: vertical !important;
          }
              `}
        </style>
          {!isLoggedIn &&(
              <div
                  className="flex absolute w-full top-0 tracking-wide overflow-hidden bg-gray-100 tx_3e52bb md:h-7 h-7 text-12 text-white z-50 font-semibold items-center justify-center cursor-pointer xl:-mb-0 -mb-0.5"
                  onClick={() => {
                      const journal_id = 330;
                      const journal_name = ('WELCOME COUPON');
                      const content = {
                          content_tab: "WELCOME COUPON",
                          content_id: String(journal_id),
                          content_name: journal_name
                      }
                      Bridge.sendAmplitude('main_content_clicked', content);
                      push(`/journal/${journal_id}`);
                  }}
              >
                  칼하트 윕 신규가입 &nbsp;<span className={`font-bold`}>앱&오프라인 5% 할인</span>&nbsp; 쿠폰&nbsp;<span
                  className={`mb-0.5`}>&gt;</span>
              </div>
          )}
        {/*<Swiper loop*/}
        {/*        effect={"slide"}*/}
        {/*        autoplay={false}*/}
        {/*        watchOverflow={true}*/}
        {/*        direction={'vertical'}*/}
        {/*        noSwiping={true}*/}
        {/*        className="mySwiper h-9 bg-white overflow-hidden"*/}
        {/*    // data-aos="fade-zoom-in"*/}
        {/*    // data-aos-duration="400"*/}
        {/*>*/}
        {/*    <SwiperSlide className={`cursor-pointer`} onClick={() => push(`journal/767`)}>*/}
        {/*        <div*/}
        {/*            className="flex relative tracking-wide overflow-hidden bg-white h-7 text-12 xl:-mx-4 tx_3e52bb z-50 font-normal items-center justify-center cursor-pointer"*/}
        {/*        >*/}
        {/*            카카오페이 결제 시 &nbsp;<span className={`font-bold`}>5%(최대 2만원)</span>&nbsp; 즉시할인&nbsp;<span*/}
        {/*            className={`mb-0.5`}>&gt;</span>*/}
        {/*        </div>*/}
        {/*    </SwiperSlide>*/}
        {/*    <SwiperSlide className={`cursor-pointer`} onClick={() => push(`/journal/330`)}>*/}
        {/*        <div*/}
        {/*            className="flex relative tracking-wide overflow-hidden bg-white h-7 text-12 xl:-mx-4 tx_3e52bb z-50 font-normal items-center justify-center cursor-pointer"*/}
        {/*        >*/}
        {/*            칼하트 윕 신규가입 &nbsp;<span className={`font-bold`}>앱&오프라인 5% 할인</span>&nbsp; 쿠폰&nbsp;<span*/}
        {/*            className={`mb-0.5`}>&gt;</span>*/}
        {/*        </div>*/}
        {/*    </SwiperSlide>*/}
        {/*</Swiper>*/}
      </>
  );
};
