import React, {useState, useMemo} from 'react';
import {useLocation} from "react-router-dom";
import {some} from "lodash";
import {ReactComponent as Spinner} from '../assets/svg/icon-spinner.svg';
const SPINNER_HIDDEN = [
    '/p',
];

export const Loading = () => {
    const { pathname } = useLocation();

    if (
        some(SPINNER_HIDDEN, route => pathname.startsWith(route))
    ) {
        return null;
    }

  return (
      <div className="flex justify-center mt-10 pb-20 top_banner_b absolute w-full h-full items-center justify-center">
          <div className="lds-ring">
              <div>
                  <Spinner/>
              </div>
          </div>
      </div>
  );
};

export default Loading;
